import { Col, Row } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import Title from 'components/typography/Title'
import React from 'react'

interface Props {
	name: string
	title: string
	body: JSX.Element[]
	id: string
}

export default function BoardMember({ name, title, body, id }: Props) {
	return (
		<Row className="mb-20" id={id}>
			<Col>
				<img
					src={`/images/academic-advisory-board/${name.split(',')[0]}.jpg`}
					alt={name}
					className="float-none mx-auto mb-4 rounded sm:float-left sm:mx-0 sm:mr-4 sm:mb-0"
					style={{ maxHeight: 350 }}
				/>
				<Title>{name}</Title>
				<P size="sm">{title}</P>
				<hr className="mb-4" />
				<div className="prose prose-gray max-w-none text-justify prose-a:text-ab-100">{body}</div>
			</Col>
		</Row>
	)
}
