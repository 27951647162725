import { Col } from 'components/grid'
import React from 'react'

interface Props {
	name: string
	id: string
}

export default function BoardMemberCard({ name, id }: Props) {
	return (
		<Col width="w-1/3 md:w-1/5" className="mb-4 flex justify-center">
			<a className="group" href={`#${id}`}>
				<div className="flex flex-col ">
					<img src={`/images/academic-advisory-board/${name.split(',')[0]}.jpg`} alt={name} className="mx-auto max-h-40" />
					<span className="mt-3 block w-full text-center text-xs group-hover:text-ab-100">{name}</span>
				</div>
			</a>
		</Col>
	)
}
