import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import React from 'react'

import BoardMember from '../components/academic-advisory-board/BoardMember'
import BoardMemberCard from '../components/academic-advisory-board/BoardMemberCard'
import BoardMembers from '../components/academic-advisory-board/boardMembers'
import { P } from 'components/typography/Paragraph'

export default function AcademicAdvisoryBoardPage() {
	const pauloFriere = BoardMembers.slice(-1)[0]

	return (
		<Layout pageName="Academic Advisory Council">
			<Section>
				<Row className="mb-6">
					<Col className="mt-8">
						<img src="/images/academic-advisory-board/banner.png" alt="academic advisory council" />
					</Col>
				</Row>
				<Row className="items-start">
					{BoardMembers.slice(0, BoardMembers.length - 1)
						.sort((a, b) => {
							const firstName = a.name.toLowerCase()
							const nextName = b.name.toLowerCase()
							if (firstName < nextName) return -1
							if (firstName > nextName) return 1
							return 0
						})
						.map((member) => {
							return <BoardMemberCard key={`${member.id}-headshot`} name={member.name} id={member.id} />
						})}
				</Row>
			</Section>
			<Section>
				<Row>
					<Col>
						<P className="mb-16">
							American Reading Company (ARC) is honored to have a passionate Academic Advisory Council of renowned educators whose life’s work is dedicated to
							ensuring all children have the support needed to achieve at the highest levels of college, career, and civic readiness.
						</P>
					</Col>
				</Row>
				{BoardMembers.slice(0, BoardMembers.length - 1)
					.sort((a, b) => {
						const firstName = a.name.toLowerCase()
						const nextName = b.name.toLowerCase()
						if (firstName < nextName) return -1
						if (firstName > nextName) return 1
						return 0
					})
					.map((member) => {
						return <BoardMember key={`${member.id}-bio`} name={member.name} title={member.title} body={member.body} id={member.id} />
					})}
				<BoardMember name={pauloFriere.name} title={pauloFriere.title} body={pauloFriere.body} id={pauloFriere.id} />
			</Section>
		</Layout>
	)
}
