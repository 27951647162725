import React from 'react'
const BoardMembers = [
	{
		id: 'alma-flor-ada',
		name: 'Alma Flor Ada, Ph.D.',
		title: 'Professor Emerita, University of San Francisco; Award-Winning Author of Children’s Books',
		body: [
			<>
				<p>
					Dr. Alma Flor Ada is Professor Emerita at the University of San Francisco, where she has been a Distinguished Professor and Director of Doctoral Studies in
					Multicultural Education. She has devoted her life to promoting a pedagogy oriented to personal realization and social justice, a mission now carried on by her
					former students in their own positions as teacher educators. The founder and first editor-in-chief of NABE, the journal of the National Association of Bilingual
					Education, Dr. Ada has long been a visionary in the field of bilingual/dual language education. A former Radcliffe Scholar at Harvard University and Fulbright
					Research Scholar, she is an internationally renowned speaker.
				</p>
				<p>
					With a Ph.D. in literature and a lifelong love of stories, Alma Flor Ada is the author of more than 200 children’s books of poetry, narrative, plays, and
					nonfiction. Her books have received many prestigious awards, including the Christopher Medal, the Pura Belpré Medal, and the NCSS-CBC Notable Book Award. In
					2012, she received the very prestigious Virginia Hamilton Award. Dr. Ada’s{' '}
					<a href="https://www.amazon.com/Magical-Encounter-Childrens-Literature-Classroom/dp/0205355447" target="_blank" rel="noreferrer">
						<i>A Magical Encounter: Latino Children's Literature in the Classroom</i>
					</a>{' '}
					is one of her many pedagogical publications. She has received numerous recognitions for her contributions to education, including the American Education
					Research Association (AERA) Hispanic Issues Award for Research in Elementary, Secondary, and Postsecondary Education, 2008; the California Association for
					Bilingual Education (CABE) Lifetime Achievement Award, 2008; the California Council for Higher Education 2011 Award; and the American Association for Hispanics
					in Higher Education Literary Award, 2012.
				</p>
				<p>Alma Flor Ada partners with ARC to design Spanish programs and classroom libraries for bilingual, dual language, and world language classrooms.</p>
			</>
		]
	},
	{
		id: 'isabel-campoy',
		name: 'F. Isabel Campoy, Lic.',
		title: 'Award-Winning Author of Hispanic Literature, Educational Advocate, National Speaker',
		body: [
			<>
				<p>
					Having language as an equal justice mission in life has provided me with multiple opportunities to challenge preconceived notions and laws of how immigrant
					children learn. Tove Skutnabb-Kangas has denounced “linguistic genocide” since the 1960s in the United Nations. Her four volumes “Language Rights” have informed
					my thoughts and kept me as an ardent advocate of bilingualism and the importance of maintaining heritage languages in schools and social settings in the United
					States, not just as an educational advantage but as a human right.
				</p>
				<p>
					To that goal, we are promoting in-depth studies on the topic of home-school connection, collaborating in the creation and distribution of literature in Spanish,
					and encouraging publishers to publish books, whether original or in translation, in that language. Since 2009, I have been a member of ARC’s Advisory Council,
					joining their efforts to make heritage languages part of an equal justice mission in education.
				</p>
			</>
		]
	},
	{
		id: 'jim-cummins',
		name: 'Jim Cummins, Ph.D.',
		title: 'Professor Emeritus at the Ontario Institute for Studies in Education of the University of Toronto',
		body: [
			<>
				<p>
					Dr. Jim Cummins is a professor with the department of Curriculum, Teaching, and Learning at OISE. His research focuses on literacy development in educational
					contexts characterized by linguistic and socioeconomic diversity. In numerous articles and books, he has explored the nature of language proficiency and its
					relationship to literacy development, with particular emphasis on the intersections of societal power relations, teacher-student identity negotiation, and
					literacy attainment.
				</p>

				<p>
					Dr. Cummins has authored and co-authored several books on literacies in education, most notably{' '}
					<a href="https://www.amazon.com/Language-Power-Pedagogy-Bilingual-Bilingualism/dp/1853594733/" target="_blank" rel="noreferrer">
						<i>Language, Power and Pedagogy: Bilingual Children in the Crossfire</i>
					</a>
					, and has seen his work translated into Japanese and Spanish. A significant contributor to bilingual education, Dr. Cummins has been the recipient of the
					International Reading Association’s Albert J. Harris award, and he has also received an honorary doctorate in Humane Letters from the Bank Street College of
					Education in New York City.
				</p>

				<p>
					Dr. Cummins currently holds editorial positions for numerous psychology journals and has been a member of several advisory committees, including our very own
					Academic Advisory Council.
				</p>
			</>
		]
	},
	{
		id: 'jeff-duncan-andrade',
		name: 'Jeff Duncan-Andrade, Ph.D.',
		title: 'Associate Professor at SFSU, Founder of Roses in Concrete Community School & the Community Responsive Education Group, Educator',
		body: [
			<>
				<p>
					Dr. Duncan-Andrade is the Professor of Latina/o Studies and Race and Resistance Studies at San Francisco State University. He is also a founder of the{' '}
					<a href="https://rosesinconcrete.org/" target="_blank" rel="noreferrer">
						Roses in Concrete Community School
					</a>
					, a community responsive lab school in East Oakland, and the{' '}
					<a href="https://communityresponsive.org/" target="_blank" rel="noreferrer">
						Community Responsive Education Group
					</a>
					. As a classroom teacher and school leader in East Oakland, California, for the past 29 years, his pedagogy has been widely studied and acclaimed for producing
					uncommon levels of social and academic success for students. Duncan-Andrade lectures around the world and has authored numerous journal articles and book
					chapters on effective practices in schools. He has written two books, and his third book with Harvard Press is due out soon. In 2015, Duncan-Andrade was tapped
					to be a commissioner on the National Commission on Teaching & America’s Future (NCTAF) and in 2016 was part of the group of educators invited to the White House
					on National Teacher Appreciation Day by President Obama. He is also the 2019 Laureate for the prestigious Brock International Prize in Education. Duncan-Andrade
					is also consistently ranked as one of the nation’s most influential scholars by EdWeek’s Public Influence Rankings.
				</p>
				<p>
					Duncan-Andrade’s transformational work on the elements of effective teaching in schools is recognized throughout the United States and as far abroad as New
					Zealand. His research interests and publications span the areas of youth wellness, trauma responsiveness, curriculum change, teacher development and retention,
					critical pedagogy, and cultural and ethnic studies. He works closely with teachers, school site leaders, union leaders, and school district officials to help
					them develop classroom practices and school cultures that foster self-confidence, esteem, and academic success among all students. Duncan-Andrade holds a Ph.D.
					in Social and Cultural Studies in Education and a Bachelor of Arts degree in Literature, both from the University of California–Berkeley.
				</p>
			</>
		]
	},
	// {
	// 	id: 'kathy-escamilla',
	// 	name: 'Kathy Escamilla, Ph.D.',
	// 	title: 'Professor of Education',
	// 	body: [
	// 		<>
	// 			<p>
	// 				Dr. Kathy Escamilla is a Professor Emerita from the Division of Equity, Bilingualism & Biliteracy at the University of Colorado, Boulder. Her research focuses
	// 				on the development of (bi)literacy in Spanish-speaking emerging bilingual children in the United States. She has co-authored three books, most notably{' '}
	// 				<a href="https://www.amazon.com/Biliteracy-Start-Literacy-Squared-Action/dp/1934000132" target="_blank" rel="noreferrer">
	// 					<i>Biliteracy from the Start: Literacy Squared in Action</i>
	// 				</a>
	// 				, and more than 50 research articles.
	// 			</p>
	// 			<p>
	// 				As a beginning bilingual teacher in the 1970s, she was greatly influenced by the book I am Joaquin by Rodolfo “Corky” Gonzales, a leader in the Chicano Civil
	// 				Rights Movement in Denver. The book, which she read as a first-year bilingual teacher in rural Colorado, combined to ignite a passion for social justice, for
	// 				developing bilingualism in US schools.
	// 			</p>
	// 			<p>
	// 				Kathy was excited to participate in ARC’s Advisory Board when she saw the quality of the ARC staff, the dedication to Spanish/English bilingualism, and the
	// 				diversity of interests and experiences of other board members.
	// 			</p>
	// 		</>
	// 	]
	// },
	// {
	// 	id: 'manuel-escamilla',
	// 	name: 'Manuel Escamilla, Ph.D.',
	// 	title: 'BUENO Equity Assistance Center, Director',
	// 	body: [
	// 		<>
	// 			<p>
	// 				Dr. Manuel Escamilla is currently the director of the BUENO Equity Assistance Center for Region VII at the University of Colorado, Boulder. He received his
	// 				Ph.D. in Early Childhood Education from the University of Kansas and has been a professor of Bilingual Education in California and Arizona. He served as the
	// 				assistant vice president of Student Affairs at the University of Arizona and at Metropolitan State College of Denver. He has also directed Head Start programs
	// 				in Denver, Conejos-Costilla County, and Huerfano-Las Animas Counties in Colorado.
	// 			</p>
	// 		</>
	// 	]
	// },
	// {
	// 	id: 'ofelia-garcia',
	// 	name: 'Ofelia García, Ph.D.',
	// 	title: 'Professor, Urban Education, Graduate Center of the City University',
	// 	body: [
	// 		<>
	// 			<p>
	// 				I am Professor Emerita in the Ph.D. programs in Urban Education and Latin American, Iberian, and Latino Cultures at The Graduate Center of the City University
	// 				of New York. Stories in books have shaped my identity as a Latina born in Cuba. They have enabled me to enter my worlds as well as those where others dwell,
	// 				dream, sing, and walk along other paths. The work of ARC is important to me because it centers books and reading as most important in educating children to live
	// 				and love, to understand and question, to dream and resist, to re-exist. I have been fortunate that the American Educational Research Association has awarded me
	// 				three Lifetime Research Achievement Awards––Distinguished Contributions to Social Contexts in Education (2019), Bilingual Education (2017), and Second Language
	// 				Acquisition Leadership through Research (2019). I am also a member of the US National Academy of Education. For more,{' '}
	// 				<a href="https://ofeliagarciadotorg.wordpress.com/" target="_blank" rel="noreferrer">
	// 					visit www.ofeliagarcia.org
	// 				</a>
	// 				.
	// 			</p>
	// 		</>
	// 	]
	// },
	{
		id: 'alicia-grunow',
		name: 'Alicia Grunow, Ph.D.',
		title: 'Co-Founder of the Improvement Collective, Improvement Specialist, Senior Fellow at Carnegie Foundation',
		body: [
			<>
				<p>
					Dr. Alicia Grunow is an educational improvement expert, specializing in how to apply the science of improvement to help teams, organizations, and networks reach
					their improvement goals. Alicia started her career as a bilingual teacher in Denver Public Schools and then in New York City, working to improve outcomes for
					students who speak a language other than English. Through this work, she discovered a passion for redesigning systems to better meet the needs of students. She
					pursued a specialization in improvement science and spent seven years adapting these methodologies for education at the Carnegie Foundation for the Advancement
					of Teaching, where she coauthored the seminal book on the topic—
					<a href="https://www.amazon.com/Learning-Improve-Americas-Schools-Getting/dp/1612507913/" target="_blank" rel="noreferrer">
						<i>Learning to Improve: How America’s Schools Can Get Better at Getting Better</i>.
					</a>
				</p>
				<p>
					Alicia remains senior fellow at the Carnegie Foundation and co-founded the Improvement Collective, a partnership dedicated to helping organizations to build
					their improvement capacity in order to solve important problems in education and the broader social sector. She holds a B.A. in psychology from Reed College, an
					Improvement Advisor certificate from the Institute for Healthcare Improvement, and a master’s degree in economics and a Ph.D. in education from Stanford
					University.
				</p>
			</>
		]
	},
	{
		id: 'pedro-antonio-noguera',
		name: 'Pedro Antonio Noguera, Ph.D.',
		title: 'Emery Stoops and Joyce King Stoops Dean, USC Rossier School of Education',
		body: [
			<>
				<p>
					Pedro Noguera is the Emery Stoops and Joyce King Stoops Dean of the USC Rossier School of Education and prior to this he served as a Distinguished Professor of
					Education at the Graduate School of Education and Information Studies at the University of California, Los Angeles. A sociologist, Noguera’s research focuses on
					the ways in which schools are influenced by social and economic conditions as well as by demographic trends in local, regional, and global contexts. He is the
					author, co-author, and editor of 15 books. His most recent books are{' '}
					<a href="https://www.amazon.com/Search-Common-Ground-Conversations-Questions/dp/0807765163/" target="_blank" rel="noreferrer">
						<i>A Search for Common Ground: Conversations About the Toughest Questions in K-12 Education</i>
					</a>{' '}
					(Teachers College Press) with Rick Hess and{' '}
					<a href="https://www.amazon.com/City-Schools-American-Dream-Multicultural/dp/0807763861/" target="_blank" rel="noreferrer">
						<i>City Schools and the American Dream: Still Pursuing the Dream</i>
					</a>{' '}
					(Teachers College Press) with Esa Syeed.
				</p>
				<p>
					He has published over 250 research articles in academic journals, book chapters in edited volumes, research reports, and editorials in major newspapers. He
					serves on the boards of numerous national and local organizations, including the Economic Policy Institute, the National Equity Project, and The Nation. Noguera
					appears as a regular commentator on educational issues on several national media outlets, and his editorials on educational issues have appeared in The New York
					Times, The Washington Post, The Wall Street Journal, The Dallas Morning News, and the Los Angeles Times.
				</p>
				<p>
					Prior to being appointed dean of the USC Rossier School of Education, Noguera served as a Distinguished Professor of Education at the Graduate School of
					Education and Information Studies at the University of California, Los Angeles. Before joining the faculty at UCLA, he served as a tenured professor and holder
					of endowed chairs at New York University (2004–2015), Harvard University (2000–2003), and the University of California, Berkeley (1990–2000).
				</p>
				<p>
					Noguera was recently appointed to serve as a special advisor to the governor of New Mexico on education policy. He also advises the state departments of
					education in Washington, Oregon, and Nevada. From 2009–2012, he served as a trustee for the State University of New York as an appointee of the governor. In
					2014, he was elected to the National Academy of Education and Phi Delta Kappa honor society, and in 2020, Noguera was elected to the American Academy of Arts
					and Sciences. Noguera has received seven honorary doctorates from American universities, and he recently received awards from the Center for the Advanced Study
					in the Behavioral Sciences at Stanford University, from the National Association of Secondary School Principals, and from the McSilver Institute for Poverty
					Policy and Research at NYU for his research and advocacy efforts aimed at fighting poverty.
				</p>
			</>
		]
	},
	{
		id: 'stephen-peters',
		name: 'Stephen Peters, Ed.D.',
		title: 'CEO and President of The Peters Group',
		body: [
			<>
				<p>
					Stephen is the CEO and president of The Peters Group, a national education consulting company in the United States. The Peters Group has a track record of
					creating and sustaining success in schools and districts by supporting educators through a three-step process of teaching and learning.
				</p>
				<p>
					Over his 37 years in education Stephen has been a classroom teacher, assistant principal, principal, and district superintendent. Most of his experiences have
					been in schools that made significant growth in short periods of time, thus resulting in both National and State Blue-Ribbon distinction.
				</p>
				<p>
					Stephen is founder of the nationally recognized The Gentlemen’s Club and The Ladies’ Club programs which provide options for thousands of at-risk and honor
					students throughout the United States. His mentoring and self-empowering program, The Gentlemen’s Club, has been featured on The Oprah Winfrey Show as well as
					America, America, which broadcasts to two million viewers in Portugal and Brazil.
				</p>
				<p>
					He served as principal-in-residence at Orangeburg-Wilkinson High School during the 2015–2016 school year. While at of Orangeburg-Wilkinson High School in 2016,
					Stephen was featured in the BBC documentary, An American High School. The documentary was named "Best Documentary of 2016" in London and aired on the National
					Geographic channel in the U.S. during 2017.
				</p>
				<p>
					In July of 2016, Stephen became the superintendent of Laurens County School District 55 (LCSD 55 in South Carolina, his home state). The county is an
					underrepresented community. During his four-year tenure as superintendent, college scholarship funds for graduating seniors increased by more than $14 million.
					LCSD 55 was named a 2018 National School District of Distinction under his leadership, an honor awarded to school districts that are exemplary models of how
					schools and school systems work. The high school has also been named the “2019 National Tomorrow’s Tech School of the Year” by Tomorrow’s Tech Magazine.
				</p>
				<p>
					His book{' '}
					<a href="https://www.amazon.com/Choosing-Believe-Creating-Framework-Success/dp/0979002834/" target="_blank" rel="noreferrer">
						Choosing to Believe: Creating a Framework for School Success
					</a>
					, is a tool that provides a comprehensive framework for school improvement. Through his books,{' '}
					<a href="https://www.amazon.com/You-Know-Enough-about-Teach/dp/097900280X" target="_blank" rel="noreferrer">
						Do You Know Enough about Me to Teach Me
					</a>
					,{' '}
					<a href="https://www.amazon.com/Teaching-Capture-Inspire-All-Learners-ebook/dp/B008P5BZ3I" target="_blank" rel="noreferrer">
						Teaching to Capture and Inspire All Learners
					</a>
					,{' '}
					<a href="https://www.amazon.com/Process-Building-Schools-Excellence-Everyone/dp/1732564655/" target="_blank" rel="noreferrer">
						ONE: A Process for Building Schools of Excellence for Every One and Everyone
					</a>{' '}
					(co-authored with Dr. Mark Wilson), and{' '}
					<a href="https://www.amazon.com/Inspired-Learn-Must-Give-Children/dp/1880463083/" target="_blank" rel="noreferrer">
						Inspired to Learn: Why We Must Give Children Hope
					</a>
					, Stephen not only outlines his vision for the children in our schools, he goes on to share how he and his staff turned their vision into operationalist
					strategies.
				</p>
				<p>
					Stephen has served on panels as an education expert with former U.S. Secretary of Education Dr. Rod Paige in Washington, D.C. He also serves on the Board of
					Directors of the International Literacy Association where he acted as president from 2020 to 2021. Stephen also serves on university and educational advisory
					boards.
				</p>
				<p>
					He received his B.S. from Hampton University, his M.Ed. from Old Dominion University, and his Ed.D. from South Carolina State University. Stephen is one of the
					most sought-after speakers in the United States and specializes in building effective school climate/culture.
				</p>
			</>
		]
	},
	{
		id: 'irvin-scott',
		name: 'Irvin Scott, Ed.D.',
		title: 'Senior Lecturer at Harvard Graduate School of Education',
		body: [
			<>
				<p>
					Irvin Scott joined the faculty of Harvard Graduate School of Education in 2016 after five years working as the deputy director for K–12 education at the Bill
					and Melinda Gates Foundation and 20 years working as a teacher, principal, assistant superintendent, and chief academic officer.
				</p>
				<p>
					Scott is currently working on an initiative that looks at the intersection of faith and education in schools. One of the goals of the initiative is to enable
					educators to safely stay in touch with their full identities as they do their work on behalf of schools and communities. Another goal is to build strong
					partnerships between faith organizations and the schools in their communities.
				</p>
			</>
		]
	},
	{
		id: 'alfred-tatum',
		name: 'Alfred W. Tatum, Ph.D.',
		title: 'Provost and Vice President of Academic Affairs at Metropolitan State University of Denver',
		body: [
			<>
				<p>
					Dr. Alfred W. Tatum is a literacy professor in the School of Education at Metropolitan State University of Denver. Prior to his move to Colorado, he served as
					the dean of the College of Education at the University of Illinois at Chicago from 2013–2020. He also directed the UIC Reading Clinic from 2007-2020.
				</p>
				<p>
					His research and advocacy are aimed at advancing the literacy development of African American boys, both struggling and non-struggling readers and writers in
					the United States. He focuses on the roles of texts and instruction to nurture social and scientific consciousness across the disciplines as a path toward
					intellectual and personal development. He is a former middle school teacher and reading specialist. Dr. Tatum links the ability to read with the ability to
					protect and advance communities. “It’s not just about their literacy development, it’s about their lives. If that doesn’t allow us to stay steadfast, I don’t
					know what will.”
				</p>
				<p>
					Professor Tatum holds a bachelor’s degree from Northern Illinois University and a doctorate from the University of Illinois at Chicago and is the author of{' '}
					<a href="https://www.goodreads.com/book/show/845976.Teaching_Reading_to_Black_Adolescent_Males" target="_blank" rel="noreferrer">
						<i>Teaching Reading to Black Adolescent Males: Closing the Achievement Gap</i>
					</a>{' '}
					(Stenhouse Publishers, 2005);{' '}
					<a href="https://www.goodreads.com/book/show/6985784-reading-for-their-life" target="_blank" rel="noreferrer">
						<i>Reading for Their Life: (Re)building the Textual Lineages of African American Adolescent Males</i>
					</a>{' '}
					(Heinemann, 2009);{' '}
					<a href="https://www.goodreads.com/book/show/16100958-fearless-voices" target="_blank" rel="noreferrer">
						<i>Fearless Voices: Engaging a New Generation of African American Male Writers</i>
					</a>{' '}
					(Scholastic Teaching Resources, 2013); and{' '}
					<a href="https://www.amazon.com/Teaching-Black-Boys-Elementary-Grades/dp/0807766151" target="_blank" rel="noreferrer">
						<i>Teaching Black Boys in the Elementary Grades: Advancing Disciplinary Reading and Writing to Secure Their Futures</i>
					</a>{' '}
					(Teachers College Press, 2021).
				</p>
			</>
		]
	},
	{
		id: 'ron-walker',
		name: 'Ron Walker',
		title: 'Executive Director of the Coalition of Schools Educating Boys of Color',
		body: [
			<>
				<p>
					Being a member of the ARC Council of Advisors has been deeply rewarding. I joined the council because of the deep commitment the American Reading Company has
					for social justice and the disruption of inequitable education systems that continue to fail our most vulnerable students. Being fully literate in my mind
					equates to being liberated. The American Reading Company is intent on using their portfolio of amazing culturally responsive books, powerful literacy
					strategies, and tools to provide access to educational liberation for all students.
				</p>
				<p>
					I am a student of history and someone who has studied the plight and progress of Black people. One of my historic heroes is Frederick Douglass, the great
					abolitionist. One of my favorite books is The Life and Writings of Frederick Douglass by Phillip S. Foner. It opened my eyes to what individuals can overcome
					when their goal is freedom and liberation. Frederick Douglass exemplified the determination to be educated and liberated. I want all students to be educated and
					liberated.
				</p>
			</>
		]
	},
	{
		id: 'julie-washington',
		name: 'Julie Washington, Ph.D.',
		title: 'Professor in the School of Education at the University of California',
		body: [
			<>
				<p>
					Dr. Julie Washington is a professor in the School of Education at the University of California – Irvine (UCI). She is a speech-language pathologist and is a
					Fellow of the American Speech Language Hearing Association. She also directs the Learning Disabilities Research Innovation Hub (funded by the National
					Institutes of Health, Eunice Kennedy Shriver National Institute on Child Health and Human Development), and is the director of the Language Variation and
					Academic Success Lab at UCI. Dr. Washington’s research is focused on the intersection of literacy, language variation, and poverty. Specifically, her work
					concentrates on understanding the role of cultural dialect in assessment outcomes, identification of reading disabilities in school-aged African American
					children, and on disentangling the relationship between language production and comprehension in the development of early reading and language skills for
					children growing up in poverty. Dr. Washington has a B.A. in English from Spelman College, an M.S. in Speech and Language Pathology from the University of
					Michigan, and a Ph.D. in Education (Speech and Language Pathology) from the University of Michigan.
				</p>
			</>
		]
	},
	{
		id: 'jeffrey-wilhelm',
		name: 'Jeffrey Wilhelm, Ph.D.',
		title: 'Professor of English at Boise State University, Presenter, Author',
		body: [
			<>
				<p>
					Jeffrey Wilhelm is Distinguished Professor of English Education at Boise State University and is the founding director of the Maine and Boise State Writing
					Projects. He is the author or co-author of 42 books about literacy teaching and learning and has earned NCTE’s Promising Research award for{' '}
					<a href="https://www.amazon.com/You-Gotta-Book-Reflective-Adolescents/dp/0807757985/" target="_blank" rel="noreferrer">
						{' '}
						<i>You Gotta BE the Book</i>
					</a>{' '}
					and the David H. Russell Award for distinguished research for{' '}
					<a href="https://www.amazon.com/Reading-Dont-Fix-No-Chevys/dp/0867095091/" target="_blank" rel="noreferrer">
						<i>Reading Don't Fix No Chevys</i>
					</a>
					, about the literate lives of young men, and for{' '}
					<a href="https://www.amazon.com/Reading-Unbound-Kids-Need-Should/dp/0545147808/" target="_blank" rel="noreferrer">
						<i>Reading Unbound</i>
					</a>
					, about the powers of pleasure reading and how to promote them. His latest book,{' '}
					<a href="https://www.amazon.com/Planning-Powerful-Instruction-Grades-Teach-ebook/dp/B086R1Q4BR/" target="_blank" rel="noreferrer">
						<i>Planning Powerful Instruction: 7 Must-Make Moves to Transform How We Teach - and How Students Learn</i>
					</a>
					, explores how guided inquiry/cognitive apprenticeship approaches can inform instruction that works for equity, social emotional learning, deep engagement, and
					transformed ways of knowing, doing, thinking, and being. Jeff loves working with ARC because they share his deepest commitments in working toward equity and
					liberation for ALL learners through literacy.
				</p>
			</>
		]
	},
	// Always keep Paulo Freire as the last position
	{
		id: 'paulo-freire',
		name: 'Honorary Member Paulo Freire (1921-1997)',
		title: 'Educator, Author',
		body: [
			<>
				<p>American Reading Company honors Paulo Freire and his teachings, which align with ARC’s philosophy.</p>
				<p>
					Brazilian educator and a leading advocate of critical pedagogy, Freire is best known for his influential work,{' '}
					<a href="https://www.amazon.com/Pedagogy-Oppressed-Anniversary-Paulo-Freire/dp/1501314130/" target="_blank" rel="noreferrer">
						<i>Pedagogy of the Oppressed</i>
					</a>
					, which is considered one of the foundational texts of the critical pedagogy movement.
				</p>
				<p>
					“The teacher is of course an artist, but being an artist does not mean that he or she can make the profile, can shape the students. What the educator does in
					teaching is to make it possible for the students to become themselves.” ― Paulo Freire
				</p>
				<p>
					Source:{' '}
					<a href="https://en.wikipedia.org/wiki/Paulo_Freire" target="_blank" rel="noreferrer">
						Wikipedia
					</a>
				</p>
			</>
		]
	}
]
export default BoardMembers
